<template>
    <div class="col-6 col-xs-12 col-md-4">
        <v-sheet class="dense-inputs">
            <v-row no-gutters>
                <v-col cols="12" lg="12" md="12" xs="12" class="d-flex flex-row float-right">
                    <v-text-field
                        :label="$t('message.filterResults')"
                        :value="searchTerm"
                        autocomplete="off"
                        class="mt-2 mb-0 force-text-left"
                        prepend-inner-icon="mdi-filter-outline"
                        @change="searchTerm = $event"
                    ></v-text-field>
                    <v-btn class="ml-3" :loading="loading.filterResults" @click="filterResults">{{ $t('message.filter') }}</v-btn>
                    <v-btn class="ml-3" @click="searchTerm = null"><v-icon>refresh</v-icon></v-btn>
                    <ExportTable
                        :has-add-function="true"
                        :cols-to-delete="colsToDelete"
                        class="mt-0 mr-0"
                        file-name="ports"
                        table-id="portsTable"
                        style="padding-top: 2px !important;"
                        @add-action="editDialog = true"
                    ></ExportTable>
                    <HelpPopUpV2 help-page-component="AgentsListing" :no-padding="true"></HelpPopUpV2>
                </v-col>
            </v-row>
        </v-sheet>
        <v-overlay
            :value="loading.ports"
            absolute
            opacity="0.15"
        >
            <v-row>
                <v-col class="text-center">
                    <v-progress-circular
                        color="primary"
                        indeterminate
                        size="40"
                        width="6"
                    ></v-progress-circular>
                </v-col>
            </v-row>
        </v-overlay>
        <v-data-table
            :footer-props="{
                itemsPerPageOptions: [20,50,-1],
                showCurrentPage: true,
                showFirstLastPage: true
            }"
            :headers="headers"
            :height="tableHeight"
            :items="allShippingPorts"
            :items-per-page="-1"
            :search="searchTerm"
            :options.sync="tableOptions"
            :sort-by="'Shippingport.title'"
            calculate-widths
            class="mt-0 appic-table-light specification-table"
            dense
            fixed-header
            id="portsTable"
            item-key="Shippinport.id"
        >
            <template v-slot:item.index="{ item }">
                <div class="text-center">{{ allShippingPorts.indexOf(item) + 1}}</div>
            </template>
            <template v-slot:item.Shippingport.title="{ item }">
                <span class="font-weight-bold">{{ item.Shippingport.title }}</span>
            </template>
            <template v-slot:item.Country.name="{ item }">
                <span v-if="item.Country.name != null">{{ item.Country.name }}</span>
                <span v-else class="red--text">{{ $t('message.pleaseProvide') }}</span>
            </template>
            <template v-slot:item.Shippingport.id="{ item }">
                <div class="text-center">
                    <v-menu>
                        <template v-slot:activator="{ on: menu }">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on: tooltip }">
                                    <v-btn icon v-on="{ ...tooltip, ...menu}" class="ml-1">
                                        <v-icon>more_vert</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('message.moreActions') }}</span>
                            </v-tooltip>
                        </template>
                        <v-list dense>
                            <v-list-item class="font-sm" @click="openEditShippingPortDialog(item.Shippingport.id, item.Shippingport.title)">
                                <v-icon color="black lighten-1" small class="mr-1">edit</v-icon>{{ $t('message.updateShippingPort') }}
                            </v-list-item>
                            <v-list-item class="font-sm" @click="deleteShippingPort(item.Shippingport.id, item.Shippingport.title)"><v-icon small class="mr-1" color="red lighten-1">cancel</v-icon>{{ $t('message.deleteShippingPort') }}</v-list-item>
                        </v-list>
                    </v-menu>
                </div>
            </template>
        </v-data-table>
        <AddShippingPort
            :port-id="selectedPortId"
            :port-name="selectedPortName"
            :dialog.sync="editDialog"
            :update-mode="updateMode"
            @dialog-closed="editDialogClosed"
            @update-done="portUpdated"
            @create-done="portCreated"
        ></AddShippingPort>
    </div>
</template>

<script>
import {mapFields} from "vuex-map-fields";
import {mapActions} from "vuex";
// import AddShippingPort from "Components/Appic/AddShippingPort";
const AddShippingPort = () => import("Components/Appic/AddShippingPort");
const ExportTable = () => import("Components/Appic/ExportTable");
const HelpPopUpV2 = () => import("Components/Appic/Help/HelpPopUpV2");

export default {
    name: "ShippingPortsListing",
    components: { HelpPopUpV2, AddShippingPort, ExportTable },
    props: ['addDialog','reload'],
    data() {
        return {
            colsToDelete: {
                start_col: 0,
                ncols: 1
            },
            dialogs: {
                error: false,
                error_message: ""
            },
            editDialog: null,
            filterOptions: {
                office: 1
            },
            hideDefaultFooter: true,
            loading: {
                fetch: false,
                filterResults: false,
                ports: false
            },
            searchField: null,
            searchTerm: null,
            selectedPortId: null,
            selectedPortName: null,
            tableHeight: '500',
            tableOptions: {
                page: 1
            },
            totalPorts: 0,
            updateMode: false
        }
    },
    computed: {
        ...mapFields('shippingport', {
            allShippingPorts: 'allShippingPorts'
        }),
        headers () {
            return [
                // {
                //     id: 0,
                //     text: this.$t('message.no'),
                //     value: 'index',
                //     class: 'light-green lighten-3 pa-1 width-1-pct',
                //     sortable: false
                // },
                {
                    id: 1,
                    text: this.$t('message.actions'),
                    value: 'Shippingport.id',
                    class: 'light-green lighten-3 pa-1 width-1-pct',
                    sortable: false
                },
                {
                    id: 2,
                    text: this.$t('message.port'),
                    value: 'Shippingport.title',
                    class: 'light-green lighten-3 pa-1 width-20-pct',
                    sortable: true
                },
                {
                    id: 3,
                    text: this.$t('message.country'),
                    value: 'Country.name',
                    class: 'light-green lighten-3 pa-1 width-20-pct',
                    sortable: true
                },
            ]
        }
    },
    methods: {
        ...mapActions('shippingport', {
            deleteShippingPortById: 'deleteShippingPortById',
            getAllShippingPorts: 'getAllShippingPorts',
            resetState: 'resetState'
        }),
        async deleteShippingPort(val, title) {
            if(await this.$root.$confirm(this.$t('message.deletePort') + ' ' +  title, this.$t('message.confirmations.continuePortCancelAction'), {color: 'orange'})){
                this.deleteShippingPortById(val).then((status) => {
                    if(status == 'done'){
                        this.$toast.success(this.$t('message.successes.portDeleted'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'check_circle_outline'
                            }
                        )
                        this.resetState()
                        this.getAllShippingPorts()
                    } else {
                        this.$toast.error(this.$t('message.errors.portNotDeleted'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                    }
                })
            }
        },
        editDialogClosed () {
            this.editDialog = false;
            if(this.updateMode == false){
                this.$emit('add-dialog-closed')
            }
        },
        filterResults () {
            this.loading.filterResults = true
            let _this = this
            setTimeout(function(){
                _this.loading.filterResults = false
            },700)
        },
        handleResize() {
            this.tableHeight = window.innerHeight - (285);
        },
        openCreateShippingPortDialog () {
            this.updateMode = false
            this.editDialog = true
        },
        openEditShippingPortDialog (portId, portName) {
            this.selectedPortId = portId
            this.selectedPortName = portName
            this.updateMode = true
            this.editDialog = true
        },
        portCreated () {
            this.editDialog = false;
            this.resetState()
            this.$emit('reload')
        },
        portUpdated () {
            this.editDialog = false;
            this.resetState()
            this.$emit('reload')
        },
    },
    watch: {
        addDialog(value) {
            if(value) {
                this.openCreateShippingPortDialog ()
            }
        }
    },
    created() {
        this.resetState()
        if(this.allShippingPorts.length == 0) this.getAllShippingPorts()
        window.addEventListener('resize', this.handleResize)
    },
    mounted() {
        this.handleResize()
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize)
    }
}
</script>

<style>
    .font-xs {
        font-size: 0.65rem;
    }
    .mt-5px {
        margin-top: 5px !important;
    }
    .mt-6px {
        margin-top: 6px !important;
    }
    .text-start {
        padding-left: 3px !important;
        padding-top: 3px !important;
        padding-right: 3px !important;
        vertical-align: middle !important;
    }
    .truncate {
        display: inline-block;
        width: 100px !important;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .v-data-table td {
        padding: 0 3px;
        font-size: 0.8rem !important;
        line-height: 0.8rem;
    }
</style>